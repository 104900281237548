import React, { useEffect } from 'react';

const PaymentSuccess = ( { subscription } ) => {
  useEffect(clearFlashes, [])
  const trackPaymentSuccess = () => { trackEvent('js_payment_success', { file: '/pricing/PaymentSuccess.jsx' }) }

  useEffect(trackPaymentSuccess, [])
  const getStartedUrl = () => { return subscription.dielines_api ? '/docs' : '/dielines' }

  return (<>
    <br />
    <br />
    <div className="alert alert-success fade in">
      <h4>
        <i className="fa fa-check-circle"></i>
        &nbsp;
        {t('payment.thank_you_subscription_success')}
      </h4>
      <br />
      <hr />
      <center>
        <a className="btn simple-btn" href={getStartedUrl()}> { t('sign_up.get_started') } </a>
      </center>
    </div>
  </>)
}

export default PaymentSuccess;
