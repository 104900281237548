import React, { useState, useEffect, useRef }  from 'react';
import MyEventListener from 'components/common/MyEventListener.jsx'
import Constants from 'components/show_drawing/Constants.jsx';

const OptionsCommonInfr = ( { formattedValue, id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested, redrawOnChange } ) => {
  const [isVisible, setIsVisible] = useState(option.visible)
  const [defaultValue, setDefaultValue] = useState( formattedValue(option.default_value) )

  const [valueA, setValueA] = useState(undefined);  // value format = "a+b/c"
  const [valueB, setValueB] = useState(undefined);
  const [valueC, setValueC] = useState(undefined);
  const [value, setValue] = useState( defaultValue )

  const valueBRef = useRef();
  const flagToRedraw = useRef();
  const focusBEnabled = useRef();

  const [changed, setChanged] = useState(false)
  const [highlighted, setHighlighted] = useState(false)
  const parseValueToInt = (v) => Number.isNaN( parseInt(v) ) ? 0 : parseInt(v);

  const onChangeA = (e) => {
    setValueA( () => parseValueToInt(e.target.value) + "" )
    setChanged( () => true );
  }

  const onChangeB = (e) => {
    setValueB( () => Math.min(parseValueToInt(e.target.value), valueC) + "" )
    setChanged( () => true );
  }

  const onChangeC = (e) => {
    setValueC( () => parseValueToInt(e.target.value) )
    focusBEnabled.current = true;
    setChanged( () => true );
  }

  const parseValueStr = (valueStr) => {
    if(valueStr == undefined || valueStr == null) { return }

    const [a, b, c ] = getABCFromStr(valueStr);

    setValueA( () => a )
    setValueB( () => b )
    setValueC( () => c )
  }

  const getABCFromStr = (valueStr) => {
    let a = 0, b, c;
    if (typeof valueStr.includes === 'function') {
      if(!valueStr.includes('+') && !valueStr.includes('/')) {
        return [parseValueToInt(valueStr), 0, 64]
      }

      if (valueStr.includes('+')) {
        const parts = valueStr.split(/[\+\/]/);
        a = parts[0];
        b = parts[1];
        c = parts[2];
      } else {
        const parts = valueStr.split('/');
        b = parts[0];
        c = parts[1];
      }
      return [ parseValueToInt(a), parseValueToInt(b), parseValueToInt(c) ]
    } else {
      return [undefined, undefined, undefined]
    }
  }

  const [recommendedValue, setRecommendedValue] = useState(defaultValue)
  const [valueAutoCalculated, setValueAutoCalculated] = useState(option.value_formula)
  const showAbcHelperLine = () => { emitEvent(Constants.show_abc_line, { id: id, name: option.name }) }

  // {drawing_id: 37101, name: "p", value: 3, disabled: false, user_changed: false, visible: true}
  // todo what if disabled comes as true?
  // need to move this methods to other options, can we avoid copy/paste?

  const onDrawingOptionRecommendedValueChangedMsg = (e) => {
    if(e.detail.id == id && e.detail.name == option.name) {
      if(e.detail.value != undefined) {
        let newValue = formattedValue(e.detail.value)
        if(newValue == value) {
          //new value is same but we still want to signal that we are done.
          emitChangedEvent()
        }

        setRecommendedValue( () => newValue  )

        if(!e.detail.user_changed) {
          setDefaultValue( () => newValue )
          // setValue( () => setValue )
          parseValueStr(newValue)
          setValueAutoCalculated( () => e.detail.auto_calculated )
        }
      }

      if(e.detail.visible != undefined) {
        setIsVisible( () => e.detail.visible )
      }
    }
  }

  const emitChangedEvent = () => {
    emitEvent('addDebugEntry', { event: "[OptionsCommonInfr emitChangedEvent] SetValue event emitted", id: id, name: option.name, value: value, changed: changed} )
    emitEvent(Constants.drawing_option_change_completed, { id: id, name: option.name })

    if(flagToRedraw.current) {
      setRedrawRequested( () => true )
      flagToRedraw.current = false;
    }
  }

  const onDrawingOptionVisibleChangedMsg = (e) => {
    if(e.detail.drawing_id == id && e.detail.name == option.name) {
      setIsVisible( () => e.detail.visible )
    }
  }

  const onDrawingOptionValueSetMsg = (e) => {
    if(e.detail.id == id && e.detail.name == option.name) {
      emitEvent('addDebugEntry', { event: "[OptionsCommonInfr onDrawingOptionValueSetMsg] setValue", name: option.name, value: formattedValue(e.detail.value), was: value, isVisible: isVisible })
      let newValue = formattedValue(e.detail.value)
      if(newValue == value) {
        //new value is same but we still want to signal that we are done.
        emitChangedEvent()
      }

      // setValue( () => newValue )
      parseValueStr(newValue)
      addToOptionsUserChanged(option)

      if(!isVisible) {
        // hack: we consider this to be "changed" in terms of redrawing but "not changed" so we don't trigger
        // redraw if redrawOnChange is set (e.g. booleans)
        setChanged( () => true );
      }

      if(e.detail.redraw) {
        flagToRedraw.current = true
      }
    }
  }

  const onDrawingOptionRecommendationChangedAddonsMsg = (e) => {
    if(e.detail.id == id && e.detail.name == option.name) {
       emitEvent('addDebugEntry', { event: "[OptionsCommonInfr onDrawingOptionRecommendationChangedAddonsMsg]", name: option.name, detail: e.detail})
       setRecommendedValue( () => formattedValue(e.detail.value) );

       if (!optionsUserChanged.includes(option.name) ){
        let newValue = formattedValue(e.detail.value)
        setDefaultValue( () => newValue )
        // setValue( () => newValue )
        parseValueStr( newValue )
        setValueAutoCalculated( () => true )
      }
    }
  }

  const onDrawingOptionHighlightMsg = (e) => {
    if(e.detail.id == id && e.detail.name == option.name) {
      setHighlighted( (prev) => e.detail.value );
    }
  }

  const onDrawingOptionUnHighlightMsg = (e) => {
    if(e.detail.id == id) {
      setHighlighted( (prev) => false );
    }
  }

  const onOptionsUserChanged = () => {
    if (optionsUserChanged.includes(option.name) ){
      setValueAutoCalculated( () => false);
    }
  }

  const d_input_add_on_ref = useRef();
  const emitLabelWidth = () => {
    emitEvent(Constants.drawing_input_label_width, { id: id, name: option.name, width: $(d_input_add_on_ref.current).width() })
  }

  const adjustLabelWidthMsg = (e) => {
    if(e.detail.id == id && e.detail.name != option.name) {
      let myWidth = $(d_input_add_on_ref.current).width();
      let othersWidth = e.detail.width;
      if(othersWidth > myWidth) {
         $(d_input_add_on_ref.current).width(othersWidth);
      }
    }
  }

  const handleValueWrappedSet = () => {
    setValue( () => `${valueA}+${valueB}/${valueC}` )
  }

  // useEffect starts
  useEffect( () => {
    if( value == 'undefined+undefined/undefined' || value == undefined || value == null || value == "") { return }
    let a1 = getABCFromStr(value);
    let a2 = getABCFromStr(defaultValue);
    if (a1[0] != a2[0] || a1[1] != a2[1] || a1[2] != a2[2] ) {
      addToOptionsUserChanged(option);
    }
  }, [value] )

  useEffect(emitChangedEvent, [value] )

  useEffect( onOptionsUserChanged, [optionsUserChanged] )

  if(redrawOnChange) {
    useEffect( () => {
      setRedrawRequested( () => changed )
    } , [value] )
  }

  useEffect( () => {
    setDefaultValue( () => formattedValue(option.default_value) )
    // setValue( () => formattedValue(option.default_value) )
    parseValueStr( formattedValue(option.default_value)  )
  } , [option.default_value])

  useEffect( () => setRecommendedValue( () => defaultValue )  , [defaultValue])
  useEffect( () => setValueAutoCalculated( () => option.value_formula), [option])
  useEffect(emitLabelWidth, [d_input_add_on_ref.current])
  useEffect(handleValueWrappedSet, [ valueA, valueB, valueC ])
  useEffect(() => {
      parseValueStr(defaultValue)
    }, [ defaultValue ])
  useEffect(() => { if(valueB == 0 && focusBEnabled.current) { $(valueBRef.current).focus() } }, [ valueB ])
  useEffect(() => {
    if(valueC < valueB) {
      setValueB( () => 0 )
    }
    }, [ valueC ])

  // useEffect ends

  MyEventListener([
    {
      key: Constants.drawing_option_recommended_value_changed,
      callback: onDrawingOptionRecommendedValueChangedMsg
    },{
      key: Constants.drawing_option_visible_updated,
      callback: onDrawingOptionVisibleChangedMsg
    },{
      key: Constants.drawing_option_set_value,
      callback: onDrawingOptionValueSetMsg
    },{
      key: Constants.drawing_option_recommended_value_changed_add_ons,
      callback: onDrawingOptionRecommendationChangedAddonsMsg
    },{
      key: Constants.drawing_option_highlight,
      callback: onDrawingOptionHighlightMsg
    },{
      key: Constants.drawing_redraw_completed,
      callback: onDrawingOptionUnHighlightMsg
    },{
      key: Constants.drawing_addon_redraw_completed,
      callback: onDrawingOptionUnHighlightMsg
    },{
      key: Constants.drawing_input_label_width,
      callback: adjustLabelWidthMsg
    },
  ])

  return [isVisible, valueA, valueB, valueC, value, valueAutoCalculated, recommendedValue, onChangeA, onChangeB, onChangeC, showAbcHelperLine, changed, highlighted, d_input_add_on_ref, valueBRef]

}

export default OptionsCommonInfr;
