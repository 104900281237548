import React, { useState, useRef, useEffect }  from 'react';
import { RedrawForm, RedrawOnAllOptionChangesComplete } from 'components/show_drawing/redraw_form/index.js';
import { GetRequest } from 'components/common/index.js';
import DrawingSvg from 'components/show_drawing/DrawingSvg.jsx';
import DebugPanel from 'components/show_drawing/DebugPanel.jsx';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import DownloadButtonsSection from 'components/show_drawing_download_section/DownloadButtonsSection';
import Constants from 'components/show_drawing/Constants.jsx';
import DrawingFooter from 'components/show_drawing/DrawingFooter.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

const ShowDrawing = ( {initialId, initialUnit, initialTheme, environment, demo, initialUserDownloadId, initialOptions} ) => {
  const debugFlag = false;
  const debug = environment == 'development' && debugFlag
  const [debugEntry, setDebugEntries] = useState([])
  const [id, setId] = useState(initialId);
  const [userDowloadId, setUserDowloadId] = useState(initialUserDownloadId);
  const [idToReset, setIdToReset] = useState(0);
  const [idForSvg, setIdForSvg] = useState(initialId);

  const [unit, setUnit] = useState(initialUnit);
  const [drawing, setDrawing] = useState( { id: initialId, main_group_chooser: {} } )
  const [drawingForSvg, setDrawingForSvg] = useState( { id: initialId, main_group_chooser: {} } )

  const [theme, setTheme] = useState( initialTheme )
  const [dimensionTextschecked, setDimensionTextschecked] = useState(true);

  const [redrawRequested, setRedrawRequested] = useState(false);
  const [redrawInProgress, setRedrawInProgress] = useState(false);
  const [downloadsDisabled, setDownloadsDisabled] = useState(false);

  const [currentDrawingOptions, setCurrentDrawingOptions] = useState({})
  const [currentUserDrawingOptions, setCurrentUserDrawingOptions] = useState({})
  const [proposedDrawingOptions, setProposedDrawingOptions] = useState({})

  const [addOns, setAddons] = useState([])
  const [addOnOptions, setAddOnOptions] = useState({})
  const [addOnsChanged, setAddOnsChanged] = useState(false)

  const [resetEnabled, setResetEnabled] = useState(false)

  const formRef = useRef();
  const optionsColRef = useRef();

  const [optionsUserChanged, setOptionsUserChanged] = useState([]);
  const addToOptionsUserChanged = (option) => {
    setOptionsUserChanged( (prev) => prev.concat(option.name).filter(onlyUnique) )
  }

  const removeFromOptionsUserChanged = (option) => {
    setOptionsUserChanged( (prev) => prev.filter(item => item != option.name).filter(onlyUnique) )
  }

  const [advancedOptionsVisible, setAdvancedOptionsVisible] = useState(false);

  const getDrawing = () => {
    // https://diecuttemplates.com.dev/drawing-data/72606
    if(id == null) { return }

    GetRequest({
      url: `/drawing-data/${id}`,
      callback: (data) => {
        setDrawing( () => data.drawing)
        window.drawing_id = id;
        emitEvent('window.drawing_id_changed',{ id: id })
        window.loggedIn = data.user.logged_in;
        setCurrentUserDrawingOptions( () => data.drawing.default_options_in_unit)
        $("#header_breadcrumbs").html(data.drawing.breadcrumbs);
        if(window.loggedIn) {
          getDrawingNote()
        }

        if($("#show_in_admin_a").length > 0) {
          $("#show_in_admin_a").attr("href", `/drawings/${id}/points`)
        }
      }
    })
  }

  const onDrawingChange = () => {
    if(!demo && initialOptions ==undefined) {
      window.history.pushState( {} , drawing.page_title, pathAndDownloadId() );
    }

    if(drawing.layouts_landing_path != undefined && $("#menu_tab_nesting").length > 0) {
      $("#menu_tab_nesting").attr("data-action", drawing.layouts_landing_path)
    }

    if(drawing.three_d_path != undefined && $("#menu_tab_3d").length > 0) {
      $("#menu_tab_3d").attr("data-action", drawing.three_d_path)
    }

    if(drawing.dielines_api_tab_path != undefined && $("#menu_tab_dielines_api").length > 0) {
      $("#menu_tab_dielines_api").attr("data-action", drawing.dielines_api_tab_path)
    }
  }

  const onRecoverySwapOptionsMsg = (msg) => {
    if(msg.detail.id != id) { return; }
    const rule = msg.detail.rule
    const first = formRef.current.querySelector(`input[name="${rule.recovery_swap_options[0]}"]`);
    const second = formRef.current.querySelector(`input[name="${rule.recovery_swap_options[1]}"]`);

    const values = {}
    values[rule.recovery_swap_options[0]] = second.value
    values[rule.recovery_swap_options[1]] = first.value

    emitEvent(Constants.drawing_set_values_and_redraw, { id: id, names: rule.recovery_swap_options, values: values })
  }

  const onRecoverySwapOptionsHighlightMsg = (msg) => {
    if(msg.detail.id != id) { return; }
    const rule = msg.detail.rule
    emitEvent(Constants.drawing_option_highlight, { id: id, name: rule.recovery_swap_options[0], value: true} )
    emitEvent(Constants.drawing_option_highlight, { id: id, name: rule.recovery_swap_options[1], value: true} )
  }

  const onRecoverySetValueMsg = (msg) => {
    if(msg.detail.id != id) { return; }
    const rule = msg.detail.rule

    emitEvent(Constants.drawing_option_set_value, {
      id: id,
      name: rule.recovery_apply_to.name,
      value: rule.recovery_apply_to.value,
      redraw: true
    })
  }

  const onRecoverySerValueHighlightMsg = (msg) => {
    if(msg.detail.id != id) { return; }
    const rule = msg.detail.rule
    emitEvent(Constants.drawing_option_highlight, { id: id, name: rule.recovery_apply_to.name, value: true} )
  }

  const onAdvancedOptionSetVisibleMsgReceived = useRef();
  const onAdvancedOptionSetVisibleMsg = (msg) => {
    if(msg.detail.id != id) { return; }
    setAdvancedOptionsVisible( () => msg.detail.value )
    onAdvancedOptionSetVisibleMsgReceived.current = true;
  }

  const onAddAddOnMsg = (msg) => {
    if(msg.detail.id != id) { return }

    for (const [addOnId, options] of Object.entries(msg.detail.options)) {
      var optionNames = Object.keys(options)
      for(let i = 0; i< optionNames.length; i++) {
        addToOptionsUserChanged( {name: optionNames[i]} )
      }
      addAddOnToDieline( addOnId, options);
    }

  }

  const addAddOnToDieline = (id, options) => {
    setAddons( (prev) =>  Array.from(new Set( prev.concat( parseInt(id) ) )) )
    setAddOnOptions( (prev) => {
      prev[id] = options;
      return { ...prev }
    } )
    setAddOnsChanged( () => true )
  }

  const removeAddOnFromDieline = (id) => {
    setAddons ( (prev) => prev.filter(_id => parseInt(_id) != parseInt(id)) )
    setAddOnsChanged( () => true )
    setRedrawRequested( () => true )
  }

  const addOnsAsQueryStr = () => {
    var query = "";
    for(var i = 0; i< addOns.length; i++) {
      query = query + '&add_ons[]=' + addOns[i];
      for (const [key, value] of Object.entries( addOnOptions[ addOns[i] ] )) {
        query = `${query}&${key}=${value}`
      }
    }
    return query;
  }

  const GetRequestPath = () => {
    const formDataJSON = {
      options_user_changed: optionsUserChanged.join(','),
      dimension_texts_included: true,
      advanced_options_visible: advancedOptionsVisible ? 1 : 0,
      _ts: Date.now(),
    };

    (new FormData(formRef.current)).forEach((value, key) => { formDataJSON[key] = value });
    window.GetRequestPathData = formDataJSON;

    if(formDataJSON["material_id"] == undefined && formDataJSON["malzeme"] == undefined) {
      return ''
    }

    return `${id}?${new URLSearchParams(formDataJSON).toString()}&${addOnsAsQueryStr()}`
  }

  const pathAndDownloadId = () => {
    if(drawing == undefined || drawing.path == undefined) { return '' }
    if(userDowloadId == undefined || userDowloadId == '') { return drawing.path }
    return drawing.path + '/' + userDowloadId;
  }

  const onResetMsg = (msg) => {
    if(msg.detail.id == id) {
      setIdToReset ( () => id )
      setId( () => null )
    }
  }

  const performReset = () => {
    if(id != null) { return }
    if(idToReset == 0) { return }

    setCurrentUserDrawingOptions( () => {} )
    setAdvancedOptionsVisible( () => false )
    setCurrentDrawingOptions( () => {} )
    setId( () => idToReset );
    setAddons( () => [] )
    setOptionsUserChanged( () => [] )
  }

  // useEffect starts
  useEffect(getDrawing, [id])
  useEffect( () => {
    setIdForSvg( () => id );
    setDrawingForSvg( () => drawing );
  }, [drawing])

  useEffect( onDrawingChange, [drawing])
  useEffect(() => { setRedrawRequested( () => addOnsChanged ) }, [addOnOptions])
  useEffect(() => { setCookie("unit", unit, 365)  }, [unit])

  useEffect(() => {
    if(unit != initialUnit) {
      setUserDowloadId( () => undefined )
      emitEvent(Constants.drawing_reset_form, { id: id })
    }
  }, [unit])

  useEffect( window.get_request_path = GetRequestPath, [])
  useEffect(performReset, [id, idToReset])

  useEffect( () => {
    emitEvent('addDebugEntry', { event: "[ShowDrawing] ID changed" , id: id })
  }, [id])

  useEffect(() => {
    if(advancedOptionsVisible && onAdvancedOptionSetVisibleMsgReceived.current) {
      emitEvent(Constants.advanced_options_set_visible_complete, { id: id } )
      onAdvancedOptionSetVisibleMsgReceived.current = false;
    }
  }, [advancedOptionsVisible])

  useEffect( () => {
    if(redrawInProgress) {
      disableMenuTabs();
    }
  },  [redrawInProgress])

  useEffect( () => {
    if(downloadsDisabled) {
      disableMenuTabs();
    } else {
      enableMenuTabs();
    }
  },[downloadsDisabled])
  useEffect( () => { if(theme != initialTheme) { setUserParam('redraw_theme', theme, () => {} ) } } , [theme])

  // useEffect ends
  MyEventListener([
    {
      key: 'addDebugEntry',
      callback: (msg) => { setDebugEntries( (prev) => prev.concat( [msg.detail] )) }
    },
    {
      key: Constants.drawing_recovery_swap_options_required,
      callback: onRecoverySwapOptionsMsg
    },{
      key: Constants.drawing_recovery_swap_options_highlight_required,
      callback: onRecoverySwapOptionsHighlightMsg
    },{
      key: Constants.drawing_recovery_set_value_required,
      callback: onRecoverySetValueMsg
    },{
      key: Constants.advanced_options_set_visible,
      callback: onAdvancedOptionSetVisibleMsg
    },{
      key: Constants.drawing_recovery_set_value_highlight_required,
      callback: onRecoverySerValueHighlightMsg,
    }, {
      key: 'user.session_started',
      callback: getDrawing
    },{
      key:  Constants.drawing_reset_form,
      callback: onResetMsg
    },{
      key:  Constants.drawing_add_add_on,
      callback: onAddAddOnMsg
    }
  ])

  if(id == null) { return }
  return (
    <div className="container">

        <div className="row">
          <div className={`col-lg-12`}>
            <div className={`col-lg-3 ${styles.optionsCol} ${redrawInProgress ? 'disabledForm' : ''}`} id="optionsCol" ref={optionsColRef}>
              {debug && <>[{id}]</>}
              <form ref={formRef}>
                <RedrawForm
                  id={id}
                  userDowloadId={userDowloadId}
                  setId={setId}
                  unit={unit}
                  setUnit={setUnit}
                  drawing={drawing}
                  theme={theme}
                  setRedrawRequested={setRedrawRequested}
                  optionsUserChanged={optionsUserChanged}
                  addToOptionsUserChanged={addToOptionsUserChanged}
                  removeFromOptionsUserChanged={removeFromOptionsUserChanged}
                  redrawInProgress={redrawInProgress}
                  debug={debug}
                  formRef={formRef}
                  GetRequestPath={GetRequestPath}
                  currentDrawingOptions={currentDrawingOptions}
                  addAddOnToDieline={addAddOnToDieline}
                  removeAddOnFromDieline={removeAddOnFromDieline}
                  addOnsAsQueryStr={addOnsAsQueryStr}
                  advancedOptionsVisible={advancedOptionsVisible}
                  setAdvancedOptionsVisible={setAdvancedOptionsVisible}
                  resetEnabled={resetEnabled}
                  setResetEnabled={setResetEnabled}
                  dimensionTextschecked={dimensionTextschecked}
                  setDimensionTextschecked={setDimensionTextschecked}
                  initialOptions={initialOptions}
                />
                </form>
            </div>

            <div className={`col-lg-9 ${styles.svgCol}`}>
              {debug && <> <DebugPanel debugEntry={debugEntry} setDebugEntries={setDebugEntries} /> <hr /> </>}

              <DrawingSvg
                id={idForSvg}
                drawing={drawingForSvg}
                unit={unit}
                theme={theme}
                setTheme={setTheme}
                redrawRequested={redrawRequested}
                setRedrawRequested={setRedrawRequested}
                setRedrawInProgress={setRedrawInProgress}
                redrawInProgress={redrawInProgress}
                formRef={formRef}
                optionsUserChanged={optionsUserChanged}
                debug={debug}
                GetRequestPath={GetRequestPath}
                setCurrentDrawingOptions={setCurrentDrawingOptions}
                setCurrentUserDrawingOptions={setCurrentUserDrawingOptions}
                setProposedDrawingOptions={setProposedDrawingOptions}
                advancedOptionsVisible={advancedOptionsVisible}
                setResetEnabled={setResetEnabled}
                optionsColRef={optionsColRef}
                setDownloadsDisabled={setDownloadsDisabled}
                dimensionTextschecked={dimensionTextschecked}
                resetEnabled={resetEnabled}
              />
            </div>
          </div>
        </div>


        <div className="col-lg-3"> </div> <div className="col-lg-9" >

          <DrawingFooter
            id={id}
            unit={unit}
            drawing={drawing}
            theme={theme}
            setTheme={setTheme}
          />

        </div>

        <div className="row">
          <div className={`col-lg-12`} className={`${downloadsDisabled ? 'disabledForm' : ''}`} >

            <DownloadButtonsSection
              id={id}
              drawing={drawing}
              currentDrawingOptions={currentDrawingOptions}
              advancedOptionsVisible={advancedOptionsVisible}
              dimensionTextschecked={dimensionTextschecked}
              currentUserDrawingOptions={currentUserDrawingOptions}
              redrawInProgress={redrawInProgress}
            />

          </div>
        </div>

      </div>

  )
}

export default ShowDrawing;
