import React, { useState, useEffect, useRef } from 'react';
import { GetRequest } from 'components/common/index.js';
import Constants from 'components/show_drawing/Constants.jsx';
import ValidationRule from 'components/show_drawing/ValidationRule.jsx';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'

const DrawingSvg = ( { id, drawing, unit, theme, setTheme, redrawRequested, setRedrawRequested, formRef, optionsUserChanged, redrawInProgress, setRedrawInProgress, debug, GetRequestPath, setCurrentDrawingOptions, setCurrentUserDrawingOptions, setProposedDrawingOptions, advancedOptionsVisible, setResetEnabled, optionsColRef, setDownloadsDisabled, dimensionTextschecked, resetEnabled} ) => {
  const key = () => { return `${Object.values(drawing).join}` }
  const minHeight = Constants.SVG_minHeight;
  const [height, setHeight] = useState(0);
  const [svg, setSvg] = useState("");
  const [isPreview, setIsPreview] = useState(true);
  const [rules, setRules] = useState([]);
  const [logInRequired, setLogInRequired] = useState(false);
  const [hasRecommendations, setHasRecommendations] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const ValidationRuleRef = useRef();
  const redraw = () => {
    if(!redrawRequested) { return; }
    setRedrawInProgress( () => true )

    const requestPath = GetRequestPath();

    if(requestPath == "") {
      setTimeout(redraw, 250)
      // trackEvent('js_error_happened',  { error: 'requestPath has no material_id' } );
      return
    }

    GetRequest({
      url: `/svg/${requestPath}`,
      callback: onRedrawSuccess,
      400: onBadRequest
    })

  }
  const fetchDrawingOptionValues = () => {
    GetRequest({
      url: `/drawing_option_values_json/${GetRequestPath()}`,
      callback: (data) => {
        data.map(processDrawingOptionResult)
      }
    })
  }

  const processDrawingOptionResult = (option) => {
    emitEvent(Constants.drawing_option_recommended_value_changed, {
      id: option.drawing_id,
      name: option.name,
      value: option.value,
      disabled: option.disabled,
      visible: option.visible,
      user_changed: option.user_changed,
      auto_calculated: option.auto_calculated
    })
  }

  const storeInLocalStorage = (formula_options) => {
    if (!isLocalStorageSupported()) { return }
    let keyForLocalStorage = `fo_${drawing.main_id}_${unit}`;
    let selectedKeys = formula_options["options_user_changed"].split(",");

    selectedKeys = selectedKeys.concat( ["material_id", "malzeme", "printing_method", "add_ons"] )
    if(drawing.show_internal_dimensions && formula_options["internal_dimensions"]) { selectedKeys = selectedKeys.concat( ["internal_dimensions"] ) }

    if (formula_options["add_ons"] != undefined && formula_options["add_ons"] != '' ){
      for(let i = 0; i< formula_options["add_ons"].length; i++) {
        selectedKeys = selectedKeys.concat( drawing.add_on_option_names[ formula_options["add_ons"][i] ] )
      }
    }

    const toStore = selectedKeys.reduce((acc, key) => {
      if (formula_options.hasOwnProperty(key)) {
        acc[key] = formula_options[key];
      }
      return acc;
    }, {});

    toStore.advancedOptionsVisible = advancedOptionsVisible;

    localStorage.setItem(keyForLocalStorage, JSON.stringify(toStore));
    emitEvent('addDebugEntry', { event: "writingLocalStore", user_options: toStore })

    setResetEnabled( () => true );
  }

  const emitDoneMessage = () => {
    emitEvent('addDebugEntry', { event: "[DrawingSvg] setSvg", id: id })
    emitEvent(Constants.drawing_svg_updated, { id: id })
  }

  const onRedrawSuccess = (data) => {
    emitEvent('addDebugEntry', {
      event: "onRedrawSuccess",
      _from: window.GetRequestPathData,
      _to: data.formula_options,
      user_formula_options: data.user_formula_options
       })

    if(data.preview != undefined) {
      if(data.preview) {
        setSvg( () => data.svg)

        //super edge-case, where we need to redraw twice because of add-ons in user-download or localstorage and first one was denied but this one was OK.
        noPreviewPromoHide()
        setTimeout(noPreviewPromoHide, 100)
        setTimeout(noPreviewPromoHide, 500)
      } else {
        noPreviewPromoShow(data)
      }

      window.currentDrawingOptions = data.formula_options; // legacy
      setCurrentDrawingOptions( () => data.formula_options)
      setCurrentUserDrawingOptions( () => data.user_formula_options)
      storeInLocalStorage( data.user_formula_options );
      setDownloadsDisabled( () => false );
      setIsPreview(() => data.preview)
    }

    fetchDrawingOptionValues();
    setRedrawInProgress( () => false )
    setRedrawRequested( () => false );
    setRules( () => [ ] )
    setHasRecommendations( () => data.has_recommendations )
    setRecommendations( () => data.recommendations )
    emitEvent(Constants.drawing_redraw_completed, { id: id })

    // sending DONE signal many times so LoadFromLocalStorage can clean up properly
    emitDoneMessage()
    setTimeout(emitDoneMessage, 10)
    setTimeout(emitDoneMessage, 100)
    setTimeout(emitDoneMessage, 250)
    setTimeout(emitDoneMessage, 500)
    enableMenuTabs();
  }

  const onBadRequest = (data) => {
    disableMenuTabs();
    if(data.rules.length > 0) {
      emitEvent(Constants.drawing_redraw_completed, { id: id })
      setRules( () => data.rules )
      setLogInRequired( () => data.log_in_required )
      setHasRecommendations( () => data.has_recommendations )
      setRecommendations( () => data.recommendations )
      setDownloadsDisabled( () => true );
      if(data.rules_and_formula_options_match != undefined && !data.rules_and_formula_options_match) {
        emitEvent(Constants.drawing_rules_and_formula_options_do_not_match, { id: id })
      }
    }

    setRedrawRequested(  () => false );
    setRedrawInProgress( () => false )
  }

  const hasRulesOrRecommendations = () => {
    return rules.length > 0 || (recommendations != undefined && recommendations.length > 0);
  }

  const resizeSvg = () => {
    if( $("#svg").length == 0 ) { setTimeout(resizeSvg, 250); return }
    var h = parseInt($(optionsColRef.current).height())
    var panZoomValue = 0.95;
    var panZoomInstance = getSvgPanZoom('#svg', panZoomValue);

    if(h < minHeight) { h = minHeight }
    if(hasRulesOrRecommendations()) { h = h - parseInt( $(ValidationRuleRef.current).height() ); }

    h = h + "px"
    setHeight( () => h );
    $("#svg").height( h );
    panZoomInstance.resize();
    panZoomInstance.fit();
    panZoomInstance.center();
    panZoomInstance.zoom(panZoomValue);
   }

  const getSVGUrl = () => {
    let mapping = {
      black_bg: {
        in: drawing.image.svg_in_url,
        infr: drawing.image.svg_infr_url,
        mm: drawing.image.svg_url,
      },
      white_bg: {
        in: drawing.image.svg_in_white_bg_url,
        infr: drawing.image.svg_infr_white_bg_url,
        mm: drawing.image.svg_white_bg_url,
      },
      gray_bg: {
        in: drawing.image.svg_in_gray_bg_url,
        infr: drawing.image.svg_infr_gray_bg_url,
        mm: drawing.image.svg_gray_bg_url,
      }
    }
    return mapping[theme][unit]
  }

  const svgStatus = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return Promise.resolve(res)
    } else {
      setRedrawRequested( () => true );

      trackEvent('svg_fetch_failed', {
        url: getSVGUrl(),
        id: id
      });
      return Promise.reject(new Error(res.statusText))
    }
  }

  const showDefaultSvg = () => {
    if(drawing.image == undefined) { return }
    var url = getSVGUrl();
    fetch(url)
    .then( svgStatus )
    .then( (response) => response.text() )
    .then(function(data) {
      setSvg( () => data );
    }).catch(function(error) {
    // console.log({error});
    });
  }

  const onOptionsUpdated = (event) => {
    if(event.detail == id) {
     resizeSvg();
    }
  }

  const timer = useRef();

  const showAbcLine = (e) => {
    if(e.detail.id != id) { return }

    $( ".dmt").fadeTo( "slow" , 0);
    $( "#abc-" + e.detail.name).fadeTo( "slow" , 1);
    $.each($('.abc'), function() {
      if(this.id != "abc-" + e.detail.name) {
        $( "#" + this.id).fadeTo( "slow" , 0);
      }
    });

    if(timer.current != undefined) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(function(){
      if( dimensionTextschecked ) { $( ".dmt").fadeTo( "slow" , 1); }
      $( ".abc").fadeTo( "slow" , 0);
    }, 8000);

  }

  // useEffect starts.....................................................................
  useEffect(showDefaultSvg, [id, drawing, theme, unit]);
  useEffect(redraw, [redrawRequested] );

  useEffect(() => {
    setTimeout(resizeSvg, 250);
  }, [svg, resetEnabled] );

  useEffect(() => {
    window.svg_initial_stroke = null;
  }, [svg] );

  useEffect( () => {
    if(svg == "") { return }
    resizeSvg()
  }, [svg, rules, recommendations, optionsColRef.current])

  useEffect( () => { dimensionTextschecked ? $(".dmt").show() : $(".dmt").hide() }, [svg, dimensionTextschecked])
  // useEffect ends

  MyEventListener([
    {
      key: Constants.options_updated,
      callback: onOptionsUpdated
    },{
      key: Constants.show_abc_line,
      callback: showAbcLine
    }
  ])

  return (
    <div key={key()}>
      {debug && <div> [{id}] [{height}] [{unit}]<br />{debug} </div> }

      <div id="mainDrawingContainer" style={{ height: height }} className={`${(redrawInProgress) ? styles.svg_in_progress : 'redraw_theme_' + theme } ${isPreview ? '' : styles.svg_is_not_preview  }`}>
        {hasRulesOrRecommendations() && <ValidationRule myRef={ValidationRuleRef} id={id} rules={rules} logInRequired={logInRequired} recommendations={recommendations} />}
        <div dangerouslySetInnerHTML={{__html: svg}}></div>
      </div>

    </div>
  )
}

export default DrawingSvg;
