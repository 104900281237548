import React, { useState, useEffect, useRef, useContext } from 'react';
import LayoutSelectButton from 'components/layout/LayoutSelectButton.jsx';
import LayoutSvg from 'components/layout/LayoutSvg.jsx';
import LayoutMostEfficient from 'components/layout/LayoutMostEfficient.jsx';
import LayoutRedrawForm from 'components/layout/LayoutRedrawForm.jsx';
import LayoutContext from 'components/contexts/LayoutContext.jsx';
import RedrawDataToPost from  'components/layout/RedrawDataToPost.jsx';

const LayoutNavSubSection = ( {sub_section, index, activeRedraws, setActiveRedraws, activeTab, items, setItems, requiredPartsMissingError}) => {
  const myLayoutContext = useContext(LayoutContext);
  const [selectedTemplateCount, setSelectedTemplateCount] = useState(0);
  const [mostEfficients, setMostEfficients] = useState([]);
  const [redrawRequested, setRedrawRequested] = useState(false);
  const [redrawInProgress, setRedrawInProgress] = useState(false);
  const recoveryApplyMethods = useRef({});
  const formRef = useRef();
  const [item, _setItem] = useState(null);
  const [activationResults, setActivationResults] = useState({});

  const setItem = (member) => {
    if(item != null) { removeItem(item) }
    _setItem(member)
    setItems((prev) => prev.concat([member]))
  }

  const removeItem = (member) => {
    _setItem(null)
    setItems((prev) => prev.filter((item) => item.uuid != member.uuid))
  }

  const selectedItemCount = (sub_section) => {
    let item_uuids = items.map((i) => i.uuid );
    let sub_section_uuids = sub_section.members.map((m) => m.uuid);
    let intersection = item_uuids.filter(value => sub_section_uuids.includes(value));
    return intersection.length;
  }

  const isMostEfficient = (member_uuid) => {
    for(let key in mostEfficients) {
      let member = mostEfficients[key];
      if(member.uuid == member_uuid) {
        return true;
      }
    }
    return false;
  }

  const fetchMostEfficients = () => {
    if(item == null) { return }

    LayoutMostEfficient(sub_section.members, item.uuid, (result) => {
      setMostEfficients((prev) => [...result])
    })
  }

  const partNames = () => {
    return sub_section.members.map((member) => { return member.part_names}).flat().filter((v, i, a) => a.indexOf(v) === i)
  }

  const partNamesInfo = () => {
    return <>
      { sub_section.members.length > 0 && partNames(sub_section).length > 0 && (<div className="partNamesInfo">
        {myLayoutContext.locale =='tr' && (<>
          <strong> {partNames(sub_section).map(str => `"${str}"`).join(', ') }</strong> için yerleştirme şablonları <br />
            </>
          ) }

        {myLayoutContext.locale =='en' && (<>
          Nesting templates for <strong> {partNames(sub_section).map(str => `"${str}"`).join(', ') }. </strong><br />
            </>
          ) }

       </div> )}
      </>
  }

  useEffect(() => {
    if(activeRedraws.length == 0) {
      fetchMostEfficients();
    }
  }, [activeRedraws]);

  useEffect(() => {
    if(index == 0 && item == null && sub_section.members.length > 0) {
      setItem(sub_section.members[0]);
    }
  }, []);

  const checkActivationFormulaResult = ( member_uuids, activeMemberUUID ) => {
    let dataToPost = RedrawDataToPost( { uuid: activeMemberUUID } ) ;
    dataToPost['member_uuids'] = member_uuids;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataToPost)
    };

    fetch(`/layouts/${activeMemberUUID}/layout_template_activation`, requestOptions)
      .then(response => response.json())
      .then((data) => {
        for (const [uuid, res] of Object.entries(data.results)) {
          setActivationResults((prev) => {
            prev[uuid] = res;
            return { ...prev }
          })
        }
      }
    );
  };

  const anyHiddenButtons = () => {
    for (const [_uuid, res] of Object.entries(activationResults)) {
      if(res == false) {
        return true;
      }
    }
    return false;
  };

  const memberUuids = () => {
    return sub_section.members.map(member => member.uuid);
  }

  const HandleActivationFormula = () => {
    let listener = function(e) {
      if(memberUuids().includes(e.detail)) {
        checkActivationFormulaResult(memberUuids(), e.detail);
      }
    };

    document.addEventListener('layout_redraw_finished', listener);
    return () => { document.removeEventListener('layout_redraw_finished', listener); }
  }

  useEffect(HandleActivationFormula, []);

  useEffect(() => {
    if(index == activeTab && index != 0) {

    memberUuids().map((uuid) => {
      setActivationResults((prev) => {
        prev[uuid] = true;
        return { ...prev }
      })
    });
    }
  }, [activeTab])

  const getLayoutSelectButton = (member, index) => {
    return activationResults[member.uuid] && <LayoutSelectButton key={member.uuid}
      activeRedraws={activeRedraws}
      member={member}
      removeItem={removeItem}
      setItem={setItem}
      items={items}
      index={index}
      isMostEfficient={isMostEfficient(member.uuid)}
    />;
  }

  return (
    <div key={`all_body_cont_${index}`} className={`${index != activeTab && 'hidden'}`}>
      <div className="col-lg-3 float-left layout-col-cont">
          { item != null && <LayoutRedrawForm
              formRef={formRef}
              item={item}
              setRedrawRequested={setRedrawRequested}
              recoveryApplyMethods={recoveryApplyMethods}
              redrawRequested={redrawRequested}
              redrawInProgress={redrawInProgress}
            /> }

            { item == null && <LayoutRedrawForm
            disabled={true}
            /> }
        </div>

        <div className="col-lg-9 float-right no-margin-right">

          { partNamesInfo() }

          {item == null && partNamesInfo() == "" && <div>  <p> {window.wording_select_template} </p> <br /></div>}

          {sub_section.members.map(getLayoutSelectButton) }
          {anyHiddenButtons() && (<> <small> <br /> <sup>*</sup>{t('layouts.note_some_templates_are_hidden')}  </small> </>) }

          {item != null && (
            <LayoutSvg klass={item.klass}
              id={item.id}
              redrawForm={formRef}
              parts={item.parts}
              redrawUrl={item.redraw_url}
              uuid={item.uuid}
              partNames={item.part_names}
              activeRedraws={activeRedraws}
              redrawRequested={redrawRequested}
              setRedrawRequested={setRedrawRequested}
              setActiveRedraws={setActiveRedraws}
              redrawInProgress={redrawInProgress}
              setRedrawInProgress={setRedrawInProgress}
              recoveryApplyMethods={recoveryApplyMethods}
            />
          )}

          {item == null && <><br /><br /></> }

           {requiredPartsMissingError() != '' && (
              <div className="col-lg-12 float-right">
                <div className="alert alert-danger fade in">
                  <span className="">
                    <i className="fa fa-exclamation-circle"></i>
                      <strong> {requiredPartsMissingError()} </strong>
                    </span>
                  <br />
                </div>
              </div>
            )}

        </div>
      </div>
      )

}

export default LayoutNavSubSection;
